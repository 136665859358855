import {
  type BlockComponent,
  useEntitySubgraph,
} from "@blockprotocol/graph/react";
import { RootEntity } from "./types.gen";
import { ConfirmedText } from "./app/confirmed-text";
import { GenerateText } from "./app/generate-text";

export const contentKey: keyof RootEntity["properties"] =
  "https://blockprotocol-9a7200lt2.stage.hash.ai/@ciaranm/types/property-type/text-content/";

export const App: BlockComponent<RootEntity> = ({
  graph: { blockEntitySubgraph, readonly },
}) => {
  const { rootEntity } = useEntitySubgraph(blockEntitySubgraph);

  const textContent = rootEntity.properties[contentKey];

  if (textContent || readonly) {
    if (!textContent) {
      return null;
    }

    return (
      <ConfirmedText
        entityId={rootEntity.metadata.recordId.entityId}
        text={textContent}
      />
    );
  }

  return <GenerateText blockEntity={rootEntity} />;
};
